import React, { useState, useEffect } from "react";
import "./BookingStyles.scss";
import LogoContainer from "../../components/logocontainer/LogoContainer";
import Button from "../../components/button/Button";
import BaseHeader from "../../components/baseheader/BaseHeader";
import { useNavigate } from "react-router-dom";
import InputComponent from "../../components/inputcomponent/InputComponent";
import TextArea from "../../components/textarea/TextArea";
import NumberInputComponent from "../../components/inputcomponent/NumberInputComponent";
import tick from "./../../assets/checkmark.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../application/store";
import {
  setEmail,
  setFirstName,
  setGuest,
  setLastName,
  setPhoneNumber,
  setRequest,
  setDate,
  setTime,
} from "../../../application/reducer/bookingSlice";
import alert from "../../assets/notification.png";
import partyIC from "../../assets/lunch.png";
import calendarIC from "../../assets/calendar.png";
import cancel from "../../assets/cancel.png";
import moment from "moment";
import { makeReservation } from "../../../infra/apis/bookings/requests/Booking";
import ErrorModal from "../../components/ReservationComponents/errorModal/ErrorModal";
const BookingPageTwo: React.FC = () => {
  const dispatch = useDispatch();
  let orgDetails = JSON.parse(localStorage.getItem("org") || "{}");
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    rules: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const guest = useSelector((state: RootState) => state.booking.guest);
  const date = useSelector((state: RootState) => state.booking.date);
  const time = useSelector((state: RootState) => state.booking.time);
  const firstName = useSelector((state: RootState) => state.booking.firstName);
  const lastName = useSelector((state: RootState) => state.booking.lastName);
  const email = useSelector((state: RootState) => state.booking.email);
  const phoneNumber = useSelector(
    (state: RootState) => state.booking.phoneNumber
  );
  const request = useSelector((state: RootState) => state.booking.request);
  const tableDuration = useSelector(
    (state: RootState) => state.booking.tableDuration
  );
  const navigate = useNavigate();
  useEffect(() => {
    const isPageReloaded = sessionStorage.getItem("isPageReloaded") === "true";

    if (isPageReloaded) {
      let id = localStorage.getItem("wrlId");
      navigate(`/booking/slots/${id}`);
    }

    sessionStorage.setItem("isPageReloaded", "true");

    return () => {
      sessionStorage.removeItem("isPageReloaded");
    };
  }, []);
  useEffect(() => {
    let id = localStorage.getItem("wrlId");
    if (id === null) {
      navigate("/");
    }
  }, []);
  const handleChecked = () => {
    setErrors({ ...errors, rules: false });
    setChecked(!checked);
  };

  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const validateNumber = (number: string) => {
    const pattern = /^\d{4} \d{6}$/;
    if (!pattern.test(number)) {
      return true;
    }
    return false;
  };
  const validateForm = (): boolean => {
    let valid = true;
    const newErrors = {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      rules: false,
    };
    const regex = /^[A-Za-z]*$/;
    if (firstName.trim() === "") {
      newErrors.firstName = "*required";
      valid = false;
    }
    if (lastName.trim() === "") {
      newErrors.lastName = "*required";
      valid = false;
    }
    if (phoneNumber.trim() === "") {
      newErrors.phoneNumber = "*required";
      valid = false;
    }
    if (validateNumber(phoneNumber)) {
      newErrors.phoneNumber = "*Invalid phone number";
      valid = false;
    }
    if (email.trim() === "") {
      newErrors.email = "*required";
      valid = false;
    } else if (!validateEmail(email)) {
      newErrors.email = "*Invalid email format";
      valid = false;
    }
    if (!regex.test(firstName.trim())) {
      newErrors.firstName = "*Only alphabetic characters are allowed.";
      valid = false;
    }
    if (!regex.test(lastName.trim())) {
      newErrors.lastName = "*Only alphabetic characters are allowed.";
      valid = false;
    }
    if (!checked) {
      newErrors.rules = true;
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const handleSubmit = () => {
    if (!isLoading) {
      if (validateForm()) {
        if (checked) {
          let reserve = {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber
              .replace(" ", "")
              .replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3"),
            email: email,
            date: moment(date).format("YYYY-MM-DD"),
            guest: guest,
            time: time,
            request: request,
            duration: tableDuration,
          };
          setIsLoading(true);
          makeReservation(reserve)
            .then((reserveObj: any) => {
              setIsLoading(false);
              if (reserveObj !== false) {
                clear();
                navigate("/booking/confirmation", { state: reserveObj });
              } else {
                setIsError(true);
              }
            })
            .catch(() => {
              setIsLoading(false);
              setIsError(true);
            });
        }
      }
    }
  };

  const handleChange = (name: any) => {
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const clear = () => {
    dispatch(setGuest(0));
    dispatch(setDate(new Date().toDateString()));
    dispatch(setTime(""));
    dispatch(setFirstName(""));
    dispatch(setLastName(""));
    dispatch(setEmail(""));
    dispatch(setPhoneNumber(""));
    dispatch(setRequest(""));
  };
  return (
    <div className='page2'>
      <div className='border' />
      <div className='BookingPageTwo'>
        <div className='left-container'>
          {/* <div className='logo-witmeg'>
            <img alt='' src={witmeg} />
          </div> */}
          <LogoContainer />
        </div>
        <div className='right-container'>
          <div className='form-container'>
            <BaseHeader />
            <div className='form'>
              <div className='header'>Booking Details</div>
              <div className='form-cont'>
                <div className='alert-container'>
                  <div className='image'>
                    <img alt='' src={alert} />
                  </div>
                  <div className='content'>
                    <div className='content-header'>Awaiting Confirmation</div>
                    <div className='content-sub'>
                      After you submit your reservation request, we will notify
                      you by phone or email once the business confirms your
                      booking.
                    </div>
                  </div>
                </div>
                <div className='rest-details'>
                  <div className='det-cont'>
                    <div className='image'>
                      <img alt='' src={partyIC} />
                    </div>
                    <div className='content'>
                      <div className='content-header'>Party of {guest}</div>
                      <div className='content-sub'>
                        {orgDetails?.OrganizationDetails?.Name}
                      </div>
                    </div>
                  </div>
                  <div className='det-cont'>
                    <div className='image'>
                      <img alt='' src={calendarIC} />
                    </div>
                    <div className='content'>
                      <div className='content-header'>
                        {moment(date).format("dddd, D MMM")}
                      </div>
                      <div className='content-sub'>{time}</div>
                    </div>
                  </div>
                </div>
                <div className='guest-container'>
                  <TextArea
                    label='Special Request'
                    value={request}
                    onChange={(value) => {
                      dispatch(setRequest(value));
                    }}
                  />
                </div>
              </div>
              <div className='header'>Contact Details</div>

              <div className='form-cont'>
                <div className='name-container'>
                  <div className='input-flex'>
                    <InputComponent
                      label='First Name'
                      value={firstName}
                      onChange={(value) => {
                        handleChange("firstName");
                        dispatch(setFirstName(value));
                      }}
                      disabled={false}
                      isRequired
                    />
                    {errors.firstName && (
                      <span className='error'>{errors.firstName}</span>
                    )}
                  </div>

                  <div className='input-flex'>
                    <InputComponent
                      label='Last Name'
                      value={lastName}
                      onChange={(value) => {
                        handleChange("lastName");
                        dispatch(setLastName(value));
                      }}
                      disabled={false}
                      isRequired
                    />
                    {errors.lastName && (
                      <span className='error'>{errors.lastName}</span>
                    )}
                  </div>
                </div>
                <div className='name-container'>
                  <div className='input-flex'>
                    <InputComponent
                      label='Email'
                      value={email}
                      onChange={(value) => {
                        handleChange("email");
                        dispatch(setEmail(value));
                      }}
                      disabled={false}
                      isRequired
                    />
                    {errors.email && (
                      <span className='error'>{errors.email}</span>
                    )}
                  </div>

                  <div className='input-flex'>
                    <NumberInputComponent
                      label='Phone Number'
                      value={phoneNumber}
                      onChange={(value: number) => {
                        handleChange("phoneNumber");
                        dispatch(
                          setPhoneNumber(
                            value
                              .toString()
                              .replace("+44 ", "")
                              .replace("+", "")
                          )
                        );
                      }}
                    />
                    {errors.phoneNumber && (
                      <span className='error'>{errors.phoneNumber}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className='header'>Rules & Restrictions</div>
              <div className='form-cont'>
                <div className='rules-container'>
                  <div className='image'>
                    <img alt='' src={cancel} />
                  </div>
                  <div className='content'>
                    <div className='content-header'>Cancellation Policy</div>
                    <div className='content-sub'>
                      If you can't make it to your reservation, please cancel at
                      least 30 minutes in advance.
                    </div>
                  </div>
                </div>
                <div
                  className='policy-content'
                  style={{ color: errors.rules ? "red" : "" }}
                >
                  {!checked ? (
                    <div className='unchecked' onClick={handleChecked} />
                  ) : (
                    <div className='checked' onClick={handleChecked}>
                      <img alt='' src={tick} />
                    </div>
                  )}{" "}
                  * By continuing, you agree to our Terms of Service and Privacy
                  Policy
                </div>
              </div>
            </div>
            <div className='button-container'>
              <div
                className='back-btn'
                onClick={() => {
                  let id = localStorage.getItem("wrlId");
                  if (id !== null) {
                    navigate(`/booking/slots/${id}`);
                  }
                }}
              >
                Back
              </div>
              <Button
                text='Submit'
                onClick={handleSubmit}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        isOpen={isError}
        onClose={() => {
          setIsError(false);
        }}
      />
    </div>
  );
};
export default BookingPageTwo;
