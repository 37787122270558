import React, { useState } from "react";
import home from "../../../assets/fav.png";
import link3 from "../../../assets/list-gray.svg";
import grade from "../../../assets/list.svg";
import grouping from "../../../assets/av-gray.svg";
import groupingPr from "../../../assets/av.svg";
import arr from "../../../assets/small-right.svg";
import arrow from "../../../assets/slide-left.svg";
import customer from "../../../assets/customer.svg";
import cust from "../../../assets/cust.svg";
import add from "../../../assets/add.svg";

import "./DrawerStyles.scss";
import { useNavigate } from "react-router-dom";
const ICONS = [
  // {
  //   img: link1,
  //   header: "Calender",
  //   type: "CALENDER",
  // },
  // {
  //   img: link2,
  //   header: "Table Reservation",
  //   type: "TABLE_RESERVATION",
  // },
  {
    img: link3,
    header: "View",
    type: "VIEW",
    subTypes: ["FLOOR", "LIST"],
    selectedImg: grade,
  },
  {
    img: grouping,
    header: "Availability",
    type: "AVAILABILITY",
    subTypes: ["TABLE GROUPING", "SHIFT MANAGEMENT"],
    selectedImg: groupingPr,
  },
  {
    img: cust,
    header: "Customer Management",
    type: "CUSTOMER",
    subTypes: [],
    selectedImg: customer,
  },
  // {
  //   img: link5,
  //   header: "Reservation Details",
  //   type: "RESERVATION_DETAILS",
  // },
  // {
  //   img: link6,
  //   header: "Pricing",
  //   type: "PRICING",
  // },
  // {
  //   img: link7,
  //   header: "Settings",
  //   type: "SETTINGS",
  // },
];
interface DrawerProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMenu: string;
  setIsWarningOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}
const Drawer: React.FC<DrawerProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  selectedMenu,
}) => {
  const getMenu = () => {
    let menu = ICONS.find((icon: any) => icon.subTypes.includes(selectedMenu));
    return menu?.type;
  };
  let openMenu = {
    type: getMenu(),
  };
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<any>(openMenu);
  const handleOnclick = (value: string) => {
    switch (value) {
      case "FLOOR":
        navigate("/v1/booking/floor-view");
        break;
      case "LIST":
        navigate("/v1/booking/list-view");
        break;
      case "ADD":
        navigate("/v1/booking/new-reservation");
        break;
      case "TABLE GROUPING":
        navigate("/v1/booking/grouping");
        break;
      case "SHIFT MANAGEMENT":
        navigate("/v1/booking/shift-management");
        break;
      default:
        navigate("/v1/booking/list-view");
        break;
    }
  };
  return (
    <div className='Drawer'>
      <div className='homeIC'>
        <div
          onClick={() => {
            handleOnclick("LIST");
          }}
          className='home-cont'
        >
          <img alt='' src={home} className='home' /> Reserve{" "}
        </div>
        <img
          alt=''
          src={arrow}
          onClick={() => {
            localStorage.setItem("drawerOpen", JSON.stringify(!isDrawerOpen));
            setIsDrawerOpen(!isDrawerOpen);
          }}
          className='arrow'
        />
      </div>
      <div
        className={`add-cont ${selectedMenu === "ADD" ? "add-Cont-sel" : ""}`}
        onClick={() => {
          handleOnclick("ADD");
        }}
      >
        <div className='addBtn'>
          <img alt='' src={add} />
        </div>
        Add Reservation
      </div>
      {ICONS.map((icon, index) => {
        return (
          <div className='list'>
            <div
              className={
                icon.subTypes.length > 0
                  ? icon.subTypes.includes(selectedMenu) &&
                    icon.type === getMenu()
                    ? "iconListSelected"
                    : "iconList"
                  : icon.type === selectedMenu
                  ? "iconListSelected"
                  : "iconList"
              }
              key={index}
              onClick={() => {
                let open = {
                  type: isOpen.type === icon.type ? "" : icon.type,
                };
                setIsOpen(open);
                if (icon.type === "CUSTOMER") {
                  navigate("/v1/booking/customer-management");
                } else {
                  if (icon.subTypes.length === 0) {
                    handleOnclick(icon.type);
                  }
                }
              }}
            >
              <div className='right'>
                <img
                  alt=''
                  src={
                    icon.subTypes.length > 0
                      ? icon.subTypes.includes(selectedMenu) &&
                        icon.type === getMenu()
                        ? icon.selectedImg
                        : icon.img
                      : icon.type === selectedMenu
                      ? icon.selectedImg
                      : icon.img
                  }
                />
                <div> {icon.header}</div>
              </div>

              {icon.subTypes.length > 0 && (
                <img
                  alt=''
                  src={arr}
                  className={isOpen.type === icon.type ? "arr-rv" : "arr "}
                />
              )}
            </div>
            {isOpen.type === icon.type
              ? icon.subTypes?.map((type, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        selectedMenu === type ? "subtype-selected " : "subtype"
                      }
                      onClick={() => {
                        let open = {
                          type: icon.type,
                        };
                        setIsOpen(open);
                        handleOnclick(type);
                      }}
                    >
                      {type.toLowerCase()}
                    </div>
                  );
                })
              : ""}
            <hr className='border' />
          </div>
        );
      })}

      <div
        style={{ height: "100%" }}
        onClick={() => {
          localStorage.setItem("drawerOpen", JSON.stringify(!isDrawerOpen));
          setIsDrawerOpen(!isDrawerOpen);
        }}
      />
    </div>
  );
};
export default Drawer;
